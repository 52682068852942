.articleContainer {
    font-size: medium;
    z-index: auto;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: space-evenly;
    max-width: 100%;
    height: available;
    position: relative;
    margin: 2em 2em 2em 9em;
    padding-right: 2em;
    background-color: #98afd2;
    border-radius: 1em;
}

.paragraphContainer {
    width: 80%;
    margin: 2em 0 2em 0;
    padding-left: 2em;
}

.videoContainer {
    width: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 2em 0 2em 0;
    padding-left: 2em;
}

.codeContainer {
    margin: 1em;
    background-color: lightsteelblue;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;

}

.codeLine {
    padding-left: 0.5em;
}

.articleContainer>h1 {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
}

.articleContainer>h3 {
    padding-left: 2em;
}

.articleContainer>ul {
    padding-left: 2em;
}