.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.slider--feature {
  text-align: center;
}

.feature--title {
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.feature--text {
  font-size: 1rem;
  color: #fff;
  margin: 1rem 0;
}


.slider--feature {
    width: 60%;
    height: 40%;
    padding: 0.4rem 1.5rem;
    background: rgba(0, 0, 0, 0.6);
    color: #fff
}

.slider--feature{
  display: flex;
  align-items:  center;
  justify-content: center;
  flex-direction: column;
  padding: 0.4rem 1.5rem;
  width: 60%;
  height: 40%;
  border: 5px none #1C6EA4;
  margin: auto;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  color: #fff;
}

.feature__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.4s;
}